<template>
  <div>
    <v-card class="mb-3">
      <v-card-title class="headline lighten-2">
        Client details
        <v-spacer />
        <div v-if="client.id !== undefined">
          <client-free-technical-review
            :client="client"
          />
          <span class="mr-1" />
          <client-dialog
            :client.sync="client"
          />
        </div>
      </v-card-title>

      <v-divider />
      <v-card-text class="pb-0">
        <div>Email: <span class="font-weight-bold">{{ client.email }}</span></div>
        <div>Phone: <span class="font-weight-bold">{{ client.phone }}</span></div>
        <div>Profile type: <span class="font-weight-bold">{{ client.profile.name }}</span></div>
        <div>Active: <active-badge :status="client.active" /></div>
      </v-card-text>
      <v-card-text class="pb-0">
        <div>ID Factory: <span class="font-weight-bold">{{ client.externalId }}</span></div>
        <div>ID eCommerce: <span class="font-weight-bold">{{ client.siteId }}</span></div>
      </v-card-text>
      <v-card-text class="pb-0">
        <div>Site: <span class="font-weight-bold">{{ client.site.name }}</span></div>
        <div>Country: <span class="font-weight-bold">{{ client.country.isoCode }}</span></div>
      </v-card-text>
      <v-card-text>
        <div>Date updated: <span class="font-weight-bold">{{ client.updatedAt | date }}</span></div>
        <div>Date created: <span class="font-weight-bold">{{ client.createdAt | date }}</span></div>
      </v-card-text>
    </v-card>


    <v-card v-if="client.id !== undefined">
      <v-tabs
        v-model="tab"
        grow
      >
        <v-tab key="tab1">
          Products
        </v-tab>
        <v-tab key="tab2">
          Viewing history
        </v-tab>
        <v-tab key="tab3">
          Withdrawal right approvals
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="tab1">
          <client-technical-reviews :client="client" />
        </v-tab-item>
        <v-tab-item key="tab2">
          <client-viewing-history :client="client" />
        </v-tab-item>
        <v-tab-item key="tab3">
          <client-withdrawal-rights :client="client" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ActiveBadge from '@/views/ui/ActiveBadge'
import ClientFreeTechnicalReview from '@/views/ClientFreeTechnicalReview'
import ClientDialog from '@/views/ClientDialog'
import ClientTechnicalReviews from '@/views/ClientTechnicalReviews'
import ClientViewingHistory from '@/views/ClientViewingHistory'
import ClientWithdrawalRights from '@/views/ClientWithdrawalRights'

export default {
  name: 'ClientDetails',
  components: {
    ActiveBadge,
    ClientDialog,
    ClientFreeTechnicalReview,
    ClientTechnicalReviews,
    ClientViewingHistory,
    ClientWithdrawalRights,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    currentClient: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: null,
        }
      },
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      tab: null,
      client: {
        profile: {},
        site: {},
        country: {},
      },
    }
  },
  mounted() {
    if (this.currentClient.id === null) {
      this.getClient()
    } else {
      this.client = this.currentClient
    }
  },
  methods: {
    getClient() {
      this.loading = true
      this.axios.get(`clients/${this.id}`).then(response => {
        this.client = response.data.data
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="text-h5">
      Clients list
    </div>
    <div class="text-right">
      <v-select
        v-model="query.perPage"
        class="d-inline-block"
        :disabled="loading"
        :items="[5, 10, 20, 50]"
        label="Items per page"
        dense
      />
    </div>

    <v-expansion-panels class="mb-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filters
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="header in headers"
            :key="header.value"
          >
            <div v-if="header.filterable">
              <v-text-field
                v-model="queryFilters[header.value]"
                :label="header.text"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :headers="headers"
      :items="clients"
      class="elevation-1"
      :loading="loading"
      hide-default-footer
      :page.sync="query.page"
      :items-per-page="query.perPage"
    >
      <template v-slot:item.active="{ item }">
        <active-badge :status="item.active" />
      </template>
      <template v-slot:item.profile.createdAt="{ item }">
        {{ item.profile.createdAt | date }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          :to="{ name: 'clients.details', params: { id: item.id.toString(), currentClient: item }}"
        >
          Details
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="query.page"
        :disabled="loading"
        :length="meta.last_page"
        total-visible="7"
      />
    </div>
    <div v-if="hasRole('api_owner', 'super_admin')">
      <v-btn
        :loading="downloading"
        @click="downloadClients"
      >
        <v-icon left>
          mdi-download
        </v-icon>
        Export
      </v-btn>
    </div>
  </div>
</template>

<script>
import { cleanObject, downloadFile } from '@/helpers/utils'
import { mapGetters } from 'vuex'
import ActiveBadge from '@/views/ui/ActiveBadge'

export default {
  name: 'Clients',
  components: {
    ActiveBadge,
  },
  data() {
    return {
      loading: false,
      downloading: false,
      headers: [
        {
          text: 'ID Factory',
          value: 'externalId',
          sortable: false,
          filterable: true,
        },
        {
          text: 'ID eCommerce',
          value: 'id',
          sortable: false,
          filterable: true,
        },
        {
          text: 'Country',
          value: 'country.isoCode',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          filterable: true,
        },
        {
          text: 'Active',
          value: 'active',
          sortable: false,
          filterable: true,
          align: 'center',
        },
        {
          text: 'Date created',
          value: 'profile.createdAt',
          sortable: false,
        },
        {
          text: null,
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      queryFilters: {},
      query: {
        page: 1,
        perPage: 10,
      },
      clients: [],
      meta: {},
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
  },
  watch: {
    'query.page'() {
      this.getClients()
    },
    'query.perPage'() {
      this.query.page = 1
      this.getClients()
    },
    'queryFilters': {
      deep: true,
      handler() {
        this.query.page = 1
        this.getClients()
      },
    },
  },
  mounted() {
    this.getClients()
  },
  methods: {
    getClients() {
      this.loading = true

      this.axios.get('clients', {
        params: {
          page: this.query.page,
          resultsPerPage: this.query.perPage,
          ...cleanObject(this.queryFilters),
        },
      }).then(response => {
        this.clients = response.data.data
        this.meta = response.data.meta
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
    downloadClients() {
      this.downloading = true
      this.axios.get('clients/export', {
        responseType: 'blob',
        headers: {
          'Content-Type': 'text/csv',
        },
      }).then(response => {
        downloadFile('clients.csv', response.data)
      }).finally(() => {
            this.downloading = false
          },
      )
    },
  },
}
</script>

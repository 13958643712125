<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        Edit
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Client</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="inputs.email"
          :error-messages="errors.email"
          label="Email address"
          placeholder="hello@example.com"
        />
        <v-text-field
          v-model="inputs.phone"
          :error-messages="errors.phone"
          label="Phone number"
          placeholder="0123456789"
        />
        <v-select
          v-model="inputs.profileId"
          :error-messages="errors.profileId"
          label="Role"
          :items="profileTypes"
        />
        <v-checkbox
          v-model="inputs.active"
          :error-messages="errors.active"
          label="Active"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ClientProfileDialog',
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      inputs: {},
      errors: {},
      profileTypes: [
        {
          text: 'API Owner',
          value: 5,
        },
        {
          text: 'Customer support',
          value: 4,
        },
        {
          text: 'Client',
          value: 3,
        },
        {
          text: 'User',
          value: 2,
        },
        {
          text: 'Super admin',
          value: 1,
        },
      ],
    }
  },
  mounted() {
    this.inputs.profileId = this.client.profile.id
    this.inputs.phone = this.client.phone
    this.inputs.email = this.client.email
    this.inputs.active = this.client.active
  },
  methods: {
    save() {
      this.loading = true
      this.axios.put(`clients/${this.client.id}`, {
        ...this.inputs,
      }).then(response => {
        this.$emit('update:client', response.data.data)
        this.dialog = false
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

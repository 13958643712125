<template>
  <div>
    <div
      v-if="loading"
      style="height: 100px"
      class="grey--text d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </div>

    <div
      v-if="firstAccesses.length"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                SKU
              </th>
              <th>
                External ID
              </th>
              <th>
                Language
              </th>
              <th>
                First access date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="firstAccess in firstAccesses"
              :key="firstAccess.id"
            >
              <td>
                {{ firstAccess.technicalReview.id }}
              </td>
              <td>
                {{ firstAccess.technicalReview.reference }}
              </td>
              <td>
                {{ firstAccess.technicalReview.externalId }}
              </td>
              <td>
                {{ firstAccess.technicalReview.technicalReviewLanguage[0].language.name }}
              </td>
              <td>
                {{ firstAccess.createdAt | date }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div
      v-else-if="!loading"
      style="height: 100px"
      class="grey--text d-flex align-center justify-center font-italic"
    >
      This user does not have any viewing history
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientWithdrawalRights',
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      firstAccesses: [],
      query: {
        page: 1,
        perPage: 100,
      },
      meta: {},
    }
  },
  mounted() {
    console.log()
    this.getTechnicalReviews()
  },
  methods: {
    getTechnicalReviews() {
      this.loading = true
      this.axios.get('logs/client-first-access', {
        params: {
          clientId: this.client.id,
          page: this.query.page,
          resultsPerPage: this.query.perPage,
        },
      }).then(response => {
        this.firstAccesses = response.data.data
        this.meta = response.data.meta
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

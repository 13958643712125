<template>
  <div>
    <div
      v-if="loading"
      style="height: 100px"
      class="grey--text d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </div>

    <div
      v-if="technicalReviews.length"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                SKU
              </th>
              <th>
                External ID
              </th>
              <th>
                Title
              </th>
              <th>
                Type
              </th>
              <th>
                Purchase date
              </th>
              <th>
                Available until
              </th>
              <th>
                Free
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="technicalReview in technicalReviews"
              :key="technicalReview.id"
            >
              <td>
                {{ technicalReview.technicalReview.id }}
              </td>
              <td>
                {{ technicalReview.technicalReview.reference }}
              </td>
              <td>
                {{ technicalReview.technicalReview.externalId }}
              </td>
              <td>
                {{ technicalReview.technicalReview.technicalReviewLanguage[0].label }}
              </td>
              <td>
                {{ technicalReview.technicalReview.technicalReviewType.label }}
              </td>
              <td>
                {{ technicalReview.dateStart | date }}
              </td>
              <td>
                <span
                  v-if="technicalReview.dateEnd"
                >
                  {{ technicalReview.dateEnd | date }}
                </span>
                <span
                  v-else
                  class="font-italic grey--text"
                >
                  No limitation
                </span>
              </td>
              <td>
                <active-badge :status="technicalReview.isFree" />
              </td>
              <td class="text-right">
                <v-btn
                  small
                  :to="{ name: 'products.details', params: { id: technicalReview.technicalReview.id.toString(), currentTechnicalReview: technicalReview.technicalReview } }"
                >
                  Details
                </v-btn>
                <span class="mr-1" />
                <delete-button
                  :route="`clients/${client.id}/access/${technicalReview.id}`"
                  @delete="$delete(technicalReviews, technicalReviews.indexOf(technicalReview))"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div
      v-else-if="!loading"
      style="height: 100px"
      class="grey--text d-flex align-center justify-center font-italic"
    >
      This user does not have any products
    </div>
  </div>
</template>

<script>
import ActiveBadge from '@/views/ui/ActiveBadge'
import DeleteButton from '@/views/ui/DeleteButton'
import eventBus from '@/plugins/eventbus'

export default {
  name: 'ClientTechnicalReviews',
  components: {
    ActiveBadge,
    DeleteButton,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      technicalReviews: [],
      query: {
        page: 1,
        perPage: 100,
      },
      meta: {},
    }
  },
  created() {
    eventBus.$on('client-technical-reviews-add', this.getTechnicalReviews)
  },
  beforeDestroy() {
    eventBus.$off('client-technical-reviews-add')
  },
  mounted() {
    this.getTechnicalReviews()
  },
  methods: {
    getTechnicalReviews() {
      this.loading = true
      this.technicalReviews = []
      this.axios.get(`clients/${this.client.id}/access`, {
        params: {
          page: this.query.page,
          resultsPerPage: this.query.perPage,
        },
      }).then(response => {
        this.technicalReviews = response.data.data
        this.meta = response.data.meta
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        Add a free product
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Add a free product</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="reference"
            :error-messages="errors.reference"
            :rules="[rules.required]"
            label="Reference"
            placeholder="SKU"
          />

          <v-btn
            :loading="loading.search"
            :disabled="!reference"
            block
            color="primary"
            @click="searchTechnicalReviews()"
          >
            Search
          </v-btn>
        </v-form>
        <div v-if="technicalReview !== undefined">
          <div class="text-h6 mt-3">
            Results
          </div>
          <div
            v-if="technicalReview === null"
          >
            No results
          </div>
          <v-card
            v-else
            color="grey lighten-3"
            class="mt-3"
            elevation="2"
          >
            <v-card-text class="py-0">
              <v-row>
                <v-col>
                  <div class="overline">
                    Reference
                  </div>
                  <span class="text-h6">
                    {{ technicalReview.reference }}
                  </span>
                </v-col>
                <v-col>
                  <div class="overline">
                    External ID
                  </div>
                  <span class="text-h6">
                    {{ technicalReview.externalId }}
                  </span>
                </v-col>
                <v-col>
                  <div class="overline">
                    Type
                  </div>
                  <span class="text-h6">
                    {{ technicalReview.technicalReviewType.label }}
                  </span>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-btn
                    color="primary"
                    :loading="loading.add"
                    block
                    @click="addFreeTechnicalReview"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus from '@/plugins/eventbus'

export default {
  name: 'ClientFreeTechnicalReview',
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        search: false,
        add: false,
      },
      dialog: false,
      reference: '',
      errors: {},
      technicalReview: undefined,
      rules: {
        required: value => !!value || 'Required',
      },
    }
  },
  methods: {
    searchTechnicalReviews() {
      this.loading.search = true
      this.technicalReview = undefined
      this.axios.get('technical-reviews', {
        params: {
          reference: this.reference,
        },
      }).then(response => {
        if (response.data.data.length) {
          this.technicalReview = response.data.data[0]
        } else {
          this.technicalReview = null
        }
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loading.search = false
      })
    },
    addFreeTechnicalReview() {
      this.loading.add = true
      this.axios.post(`clients/${this.client.id}/access`, {
        technicalReviewId: this.technicalReview.id,
        dateStart: this.$date().format('YYYY-MM-DD HH:mm:ss'),
        isFree: true,
      }).then(response => {
        eventBus.$emit('client-technical-reviews-add', this.technicalReview)
        this.dialog = false
        this.technicalReview = undefined
        this.reference = undefined
      }).catch(error => {
        this.errors = error.response.data?.errors
      }).finally(() => {
        this.loading.add = false
      })
    },
  },
}
</script>
